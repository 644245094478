'use client';

import { ToastContainer } from '@little-worker/hestia';
import { RouterProvider } from '@react-aria/utils';
import dynamic from 'next/dynamic';
import React, { ReactNode, Suspense } from 'react';

import Loading from '@/app/[locale]/loading';
import useRouterWithTracking from '@hooks/useRouterWithTracking';

const AblyContextProvider = dynamic(() => import('../../components/Common/AblyContextProvider'), {
  ssr: false,
});

export function Provider({ children }: { children: ReactNode }) {
  const { push } = useRouterWithTracking();

  return (
    <RouterProvider navigate={push}>
      <AblyContextProvider>
        <Suspense fallback={<Loading />}>{children}</Suspense>
      </AblyContextProvider>
      <ToastContainer autoClose={5000} />
    </RouterProvider>
  );
}
